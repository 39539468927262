<template>
  <main>
    <div class="pb-5">
      <PCarousel
        :value="imagesWitSlogans"
        v-if="imagesWitSlogans.length > 0"
        :numVisible="1"
        :numScroll="1"
        :circular="true"
        :autoplayInterval="8000"
        :responsiveOptions="responsiveOptions"
      >
        <template #item="{ data: item }">
          <div
            class="rounded-3"
            :style="`
                background-image: url('/assets/img/banniere/${item.imageUrl}');
                height: 92vh;
                background-size: cover;
                background-repeat: no-repeat;
              `"
          >
            <div
              class="caroussel-content text-center h-100"
              style="
                position: absolute;
                top: 60;
                height: calc(91vh-150px);
                width: 100%;
                background: rgba(0, 0, 0, 0.257);
              "
            >
              <div class="row h-100">
                <div
                  class="col-sm-12 col-md-12 d-flex align-items-center justify-content-center"
                >
                  <div
                    class="text-white d-flex align-items-center justify-content-center"
                  >
                    <div
                      class="animated bounce slower d-flex flex-column align-items-center justify-content-center"
                    >
                      <h1 class="style_titre_banner">
                        <!-- Trouvez l'emploi de vos rêves parmis plus de  -->
                        <span class="fw-bold h1 text-warning">{{ offresCount }}</span>
                        offres disponibles.
                      </h1>
                      <div
                        class="my-4 gap-2"
                        @click.prevent="$router.push({ name: 'portail.offres.list' })"
                      >
                        <button
                          class="btn btn-search style_btn btn-outline-warning border-3 btn-lg text-white shadow-3"
                          type="button"
                        >
                          <span class="text-white">
                            <i class="bi bi-search"></i>
                            Rechercher une offre d'emploi publiée sur la platefrome
                          </span>
                        </button>
                      </div>
                      <h3 class="text-white text-center mb-0 mx-5">
                        <!-- L'ANPE met à votre disposition une multitude d'offres d'emploi
                        crédibles et vérifées dont elle a la gestion ou diffusées par ses
                        partenaires. -->
                      </h3>
                    </div>
                  </div>
                  <!-- <div class="my-2 ms-md-4 ms-sm-0 text-center">
                    <router-link
                      :to="{ name: 'portail.offres.list' }"
                      class="btn bg-warning"
                    >
                      <div class="text- text-">
                        <h5 class="display- text-center">
                          <a href="#"><i class="bi bi-briefcase me-2 display-1"></i></a>
                          <div>
                            <span>Rechercher des offres d'emploi <br /></span>
                            <small>
                              (<span class="text- fw-bold">{{ offresCount }}</span>
                              offres disponibles)
                            </small>
                          </div>
                        </h5>
                      </div>
                    </router-link>
                  </div> -->
                </div>
              </div>
            </div>

            <div
              class="caroussel-content-logo"
              style="
                position: absolute;
                bottom: 150;
                left: 50;
                width: 200px;
                background: rgba(0, 0, 0, 0.02);
              "
            >
              <img src="/assets/img/prodij.png" height="150px" alt="" />
            </div>
            <div
              class="caroussel-content-bottom"
              style="
                position: absolute;
                bottom: 0;
                width: 100%;
                background: rgba(255, 255, 255, 0.08);
              "
            >
              <!-- <div class="row m-0">
                  <span class="col py-2 bg-success m-0"></span
                  ><span class="col py-2 bg-yellow m-0"></span
                  ><span class="col py-2 bg-danger m-0"></span>
                </div> -->
              <!-- <div class="container">
                <div class="d-flex justify-content-end flex-wrap"></div>
              </div> -->
              <div
                class="slogan bg-white flex-wrap py-2 d-flex flex- h-100 justify-content-around align-items-center slower"
              >
                <div class="mb-sm-4 px-sm-2">
                  <PButton
                    @click.prevent="$router.push({ name: 'accounts.register.de' })"
                    label=""
                    class="p-button-secondary"
                  >
                    <div>
                      <h4>Inscription demandeur d'emploi</h4>
                      <div>(Instruits, peu instruits, pas instruits)</div>
                    </div>
                  </PButton>
                </div>
                <div class="mb-sm-4 px-sm-2">
                  <h4 class="text-uppercase">{{ item.slogan.title }}</h4>
                  <h5 class="text-success-pro text-uppercase">
                    {{ item.slogan.subtitle }}
                  </h5>
                  <a class="text-danger fw-bold" href="#">Inscrivez-vous maintenant</a>
                </div>
                <div class="px-sm-2">
                  <PButton
                    label=""
                    class="p-button-help"
                    @click.prevent="$router.push({ name: 'accounts.register.ent' })"
                  >
                    <div>
                      <h4>Incription des employeurs</h4>
                      <div>(Personne physique, personne morale)</div>
                    </div>
                  </PButton>
                </div>
              </div>
            </div>
          </div>
        </template>
      </PCarousel>
    </div>

    <div class="container mb-5 mt-5">
      <div class="row">
        <div class="col-md-12 col-sm-12 bg-white py-3 shadow">
          <h2 class="text-uppercase">Je suis une entreprise.</h2>
          <h3 class="text-success-pro">
            Comment devenir partenaire du dispositif Azôli ?
          </h3>
        </div>
        <div class="col-md-12 col-sm-12 bg-white p">
          <div class="card rounded border-0 rounded-8">
            <div class="card-body">
              <video width="100%" height="440" controls>
                <source
                  src="/assets/videos/Tuto_inscription_des_entreprises_Video_Proximity.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mt-4" v-for="(item, i) in imagesWitSlogans" :key="i">
        <div
          class="col-sm-12 col-md-6 shadow"
          :class="{ 'order-2': i % 2 == 0 }"
          :style="`
                background-image: url('/assets/img/banniere/${item.imageUrl}');
                background-size: cover;
                background-repeat: no-repeat;
                height: 300px;
              `"
        ></div>
        <div
          class="col-sm-12 col-md-6 bg-white shadow py-4 px-4 d-flex flex-column justify-content-between"
        >
          <div class="slogan">
            <h3 class="text-uppercase">{{ item.slogan.title }}</h3>
            <h4 class="text-success-pro text-uppercase">
              {{ item.slogan.subtitle }}
            </h4>
            <a class="text-danger fw-bold" href="#">Inscrivez-vous maintenant</a>
          </div>
          <div class="slogan-contact d-flex justify-content-between align-items-center">
            <img alt="Logo anpe" height="80px" src="/assets/img/anpe.png" />
            <div class="contact-info d-flex flex-column">
              <span
                style="border-radius: 15px"
                class="bg-success-pro text-white py- px-4 mb-2"
              >
                <i class="pi pi-phone me-2"></i> 51.10.33.33 / 51.10.66.66
              </span>
              <span
                style="border-radius: 15px"
                class="bg-success-pro text-white py- px-4"
              >
                <i class="pi pi-globe me-2"></i>www.sica.anpe.bj</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container my-5">
      <div class="row mb-4" v-for="(item, i) in testimonies" :key="i">
        <div
          :class="{ 'order-2': i % 2 == 0 }"
          class="col-sm-12 col-md-6 rounded rounded-4"
          :style="`
                background-image: url('/assets/img/testimonies/${item.imageUrl}');
                background-size: cover;
                background-repeat: no-repeat;
                height: 300px;
              `"
        ></div>
        <div
          class="col-sm-12 col-md-6 bg-white rounded rounded-8 py-4 px-4 d-flex flex-column justify-content-between"
        >
          <div class="slogan">
            <h3 class="text-uppercase">AUJOURD’HUI</h3>
            <h4 class="text-success-pro text-uppercase">
              {{ item.title }} <br />
              {{ item.title2 }}
            </h4>
            <span class="fw-bold">{{ item.captionPrefix }}</span>
            <span class="text-danger fw-bold">{{ item.captionSuffix }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-5">
      <div class="row">
        <div class="col-sm mb-2">
          <router-link
            :to="{ name: 'accounts.register.de' }"
            class="card h-100 p-4 border-0 rounded-3 bg-success-pro"
          >
            <div class="text-center mx-auto text-white">
              <h1 class="display-4 text-yellow mb-3">
                <a href="#"><i class="bi bi-card-checklist me-2 display-1"></i></a>
              </h1>
              <h4>
                Inscription demandeur d'emploi
                <br />
                (Instruits, peu instruits, pas instruits)
              </h4>
            </div>
          </router-link>
        </div>
        <!-- <div class="col-sm mb-2">
          <router-link
            :to="'#'"
            class="card h-100 p-4 border-0 rounded-3 bg-primary"
          >
            <div class="text-center mx-auto text-white">
              <h1 class="display-4 text-yellow mb-3">
                <a href="#"><i class="bi bi-person me-2 display-4"></i></a>
              </h1>
              <h5>
                Créer mon dossier<br />(Instruits et peu ou pas instruits)
              </h5>
            </div>
          </router-link>
        </div> -->
        <!-- <div class="col-sm mb-2">
          <div class="card h-100 p-4 border-0 rounded-3 bg-primary">
            <div class="text-center mx-auto text-white">
              <h1 class="display-4 text-yellow mb-3">
                <a href="#"><i class="bi bi-list-check me-2 display-4"></i></a>
              </h1>
              <h5>
                Rechercher un emploi ou une compétence
                <br />
              </h5>
            </div>
          </div>
        </div> -->
        <div class="col-sm mb-2">
          <router-link
            :to="{ name: 'accounts.register.ent' }"
            class="card h-100 p-4 border-0 rounded-3 bg-success-pro"
          >
            <div class="text-center mx-auto text-white">
              <h1 class="display-4 text-yellow mb-3">
                <a href="#"><i class="bi bi-geo-alt me-2 display-1"></i></a>
              </h1>
              <h4>
                Incription des employeurs
                <br />
                (Personne physique, personne morale)
              </h4>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div id="opportunites"></div>
    <div id="actualites" class="card container bg-white rounded-3 p-4 mb-5">
      <PCarousel
        :value="actualites"
        :numVisible="3"
        :numScroll="1"
        class="custom-carousel"
        :circular="true"
        :autoplayInterval="3000"
      >
        <template #header>
          <h5>Actualités</h5>
        </template>
        <template #item="slotProps">
          <div class="product-item">
            <div class="product-item-content" v-show="slotProps.data.isPublish">
              <div class="mb-3">
                <img
                  :src="slotProps.data.fullImageUrl"
                  :alt="slotProps.data.titre"
                  class="product-image"
                  height="250"
                  width="250"
                />
              </div>

              <div>
                <h5 class="mb-1">{{ slotProps.data.titre }}</h5>
                <!-- <h6 class="mt-0 mb-3" v-html="slotProps.data.contenu"></h6> -->

                <span
                  >Publié le
                  {{ slotProps.data.datePublication | moment("DD/MM/YYYY") }}</span
                >
                <!-- <br />
                <span
                  >Expiré le
                  {{
                    slotProps.data.dateExpiration | moment("DD/MM/YYYY")
                  }}</span
                > -->

                <div class="car-buttons mt-5">
                  <b-button
                    pill
                    variant="success"
                    @click="
                      $router.push({
                        name: 'details.actualite',
                        params: { actualiteId: slotProps.data.id },
                      })
                    "
                    >Savoir plus</b-button
                  >
                  <!-- <Button icon="pi pi-search" class="p-button p-button-rounded mr-2" />
                  <b-button pill variant="success"  @click="$router.push({ name: 'details.actualite/' , params: { actualiteId: slotProps.data.id }  })"
                         <Button icon="pi pi-star-fill" class="p-button-success p-button-rounded mr-2" value="Savoir plus" />
                        -->

                  <!--  <Button icon="pi pi-cog" class="p-button-help p-button-rounded" />-->
                </div>
              </div>
            </div>
          </div>
        </template>
      </PCarousel>
    </div>

    <div id="faqs" class="container bg-white rounded-3 p-4 mb-5">
      <h3 class="fw-bold mb-3">Les FAQS</h3>
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item" v-for="(faq, i) in faqs" :key="i">
          <h2 class="accordion-header" :id="`flush-${faq.id}`">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#flush-${faq.id}`"
              aria-expanded="false"
              :aria-controls="`flush-${faq.id}`"
            >
              <h5>{{ faq.question }}</h5>
            </button>
          </h2>
          <div
            :id="`flush-${faq.id}`"
            class="accordion-collapse collapse"
            :aria-labelledby="`flush-${faq.id}`"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <p>{{ faq.reponse }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
//  import VueSlickCarousel from 'vue-slick-carousel'
import "vue-slick-carousel/dist/vue-slick-carousel.css";

// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapGetters, mapActions } from "vuex";
import PCarousel from "primevue/carousel";

export default {
  name: "Home",
  components: {
    // VueSlickCarousel
    PCarousel,
  },
  data() {
    return {
      offresCount: 0,
      images: [
        { imageUrl: "BTP_1.jpg", title: "", content: "" },
        { imageUrl: "BTP_2.jpg", title: "", content: "" },
        { imageUrl: "Agriculture_1.jpg", title: "", content: "" },
        { imageUrl: "Coordonniere_1.jpg", title: "", content: "" },
        { imageUrl: "Energie_solaire_1.jpg", title: "", content: "" },
        { imageUrl: "Hotel_Restau_1.jpg", title: "", content: "" },
        { imageUrl: "IT_1.jpg", title: "", content: "" },
      ],
      testimonies: [
        {
          imageUrl: "testimony-accueil.jpg",
          title: "JE SUIS RECEPTIONNISTE",
          title2: "D’HÔTEL…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais aucune qualification !",
        },
        {
          imageUrl: "testimony-ananas.jpg",
          title: "MA PRODUCTION PARTICIPE A BOOSTER",
          title2: "DAVANTAGE L’ECONOMIE NATIONALE…",
          subtitle: "",
          captionPrefix: "Je suis,",
          captionSuffix: "partenaire du dispositif Azôli !",
        },
        {
          imageUrl: "testimony-bois.jpg",
          title: "JE SUIS CHEFFE ATELIER",
          title2: "DANS UNE FIRME DE FABRICATION DE MEUBLES…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais aucune qualification.",
        },
        {
          imageUrl: "testimony-boostage.jpg",
          title: "MA PRODUCTION PARTICIPE A BOOSTER",
          title2: "DAVANTAGE L’ECONOMIE NATIONALE…",
          subtitle: "",
          captionPrefix: "Je suis,",
          captionSuffix: "partenaire du dispositif Azôli !",
        },
        {
          imageUrl: "testimony-btp.jpg",
          title: "JE TRAVAILLE POUR UNE",
          title2: "MULTINATIONALE…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais que le CEP !",
        },
        {
          imageUrl: "testimony-btp2.jpg",
          title: "JE SUIS OUVRIERE SPECIALISEE",
          title2: "DANS UNE SOCIETE DE BTP…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais aucun diplôme !",
        },
        {
          imageUrl: "testimony-couture.jpg",
          title: "JE TRAVAILLE DANS UNE",
          title2: "GRANDE SOCIETE TEXTILE…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais aucun diplôme !",
        },
        {
          imageUrl: "testimony-electricite.jpg",
          title: "JE SUIS OUVRIERE SPECIALISEE",
          title2: "EN INSTALLATIONS ELECTRIQUES…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais aucune qualification !",
        },
        {
          imageUrl: "testimony-electricite2.jpg",
          title: "J’INSTALLE ET J’ENTRETIENS",
          title2: "DES JARDINS POUR MES CLIENTS…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais aucune qualification !",
        },
        {
          imageUrl: "testimony-entreprise1.jpg",
          title: "MON ENTREPRISE A BESOIN D’UNE",
          title2: "MAIN D’ŒUVRE QUALIFIEE…",
          subtitle: "",
          captionPrefix: "Je suis",
          captionSuffix: "partenaire du dispositif Azôli !",
        },
        {
          imageUrl: "testimony-entreprise2.jpg",
          title: "MON ENTREPRISE A BESOIN D’UNE",
          title2: "MAIN D’ŒUVRE QUALIFIEE…",
          subtitle: "",
          captionPrefix: "Je suis",
          captionSuffix: "partenaire du dispositif Azôli !",
        },
        {
          imageUrl: "testimony-entreprise3.jpg",
          title: "MON ENTREPRISE FORME ET RECRUTE",
          title2: "DES JEUNES PEU OU PAS INSTRUITS…",
          subtitle: "",
          captionPrefix: "Je suis",
          captionSuffix: "partenaire du dispositif Azôli !",
        },
        {
          imageUrl: "testimony-garage.jpg",
          title: "JE GERE MON PROPRE",
          title2: "GARAGE…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais que le CEP !",
        },
        {
          imageUrl: "testimony-jardin.jpg",
          title: "J’INSTALLE ET J’ENTRETIENS",
          title2: "DES JARDINS POUR MES CLIENTS…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais aucune qualification !",
        },
        {
          imageUrl: "testimony-resto.jpg",
          title: "JE SUIS CHEF",
          title2: "DANS UN GRAND HÔTEL…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: "je n’avais que le CEP !",
        },
        {
          imageUrl: "testimony-solaire.jpg",
          title: "JE GERE MA PROPRE",
          title2: "CLIENTELE…",
          subtitle: "",
          captionPrefix: "Et pourtant,",
          captionSuffix: " je ne pouvais pas payer la formation !",
        },
      ],
      slogans: [
        {
          title: "Ouvrez les portes de l'emploi",
          subtitle: "Et tracez votre chemin vers la réussite.",
          target: "DE",
        },
        {
          title: "Rejoignez azoli",
          subtitle: "Et saisissez ses opportunités.",
          target: "DE",
        },
        {
          title: "Devenez partenaire et Bénéficiez",
          subtitle: "D'un vivier de talents formés selon vos besoins.",
          target: "ENTREPRISE",
        },
      ],
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 1,
          numScroll: 1,
        },
        {
          breakpoint: "600px",
          numVisible: 1,
          numScroll: 1,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  created() {
    // this.slogans = [...this.slogans, ...this.slogans, ...this.slogans];

    this.fetchOffresCount().then(({ offresCount }) => {
      this.offresCount = offresCount;
    });
    this.fetchActualites();
    this.fetchFaqs();
  },
  computed: {
    ...mapGetters({
      actualites: "portail/actualites",
      faqs: "portail/faqs",
    }),
    imagesWitSlogans() {
      return this.images.map((img) => ({
        ...img,
        slogan: this.pickSlogan(),
      }));
    },
  },
  methods: {
    ...mapActions({
      fetchActualites: "portail/fetchActualites",
      fetchOffresCount: "offre/fetchOffresCount",
      fetchFaqs: "portail/fetchFaqs",
      selectActualite: "portail/selectActualite",
    }),
    pickSlogan() {
      return this.slogans[Math.floor(Math.random() * 1000) % this.slogans.length];
    },
    unActualite() {
      console.log(this.selectActualite);
    },
  },
};
</script>

<style>
.home_background {
  background-image: url("/assets/img/pexels-tima-miroshnichenko-5198239.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 160px;
}

.btn-search {
  min-width: 300px !important;
  font-size: 1.6rem !important;
  color: #777 !important;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.2);
}

.btn-search:hover {
  /* background: navy; */
  /* color: white; */
  color: #373737;
}

.p-carousel-prev.p-link,
.p-carousel-next.p-link {
  background: pink;
  display: none;
}

.caroussel-content {
}

.p-carousel-indicator .p-link {
  background: white;
}

.text-success-pro {
  color: #468c52;
}

.bg-success-pro {
  background: #468c52;
}

.image-main {
  position: relative;
  height: 90vh;
  width: 100%;
}
.image-main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.8s;
  opacity: 0;
  background-size: 100% 100%;
}
.image-main:hover:before {
  opacity: 0.8;
}

/* @media screen {
    .home_background{
      background-image: url('/assets/img/pexels-tima-miroshnichenko-5198239.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: -160px;
    }
  } */
</style>
